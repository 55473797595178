import React, { useCallback, useState } from 'react';
import { useQueryParams } from 'use-query-params';
import { graphql } from 'gatsby';
import ReactGA from 'react-ga';
import { Box, Container, Text, SimpleGrid, Flex } from '@chakra-ui/react';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { CaseStudyLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Html from 'timelesstime-ui/components/html';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import FilterToggles from 'timelesstime-ui/components/filter-toggles';
import PageLayout from '../components/layout';
import CaseStudyCard from '../components/case-study-card';
import JSONStringifyParam from '../utils/use-query-params-json-stringify';

const GACategory = 'case-studies';

const filterToggles = [
  {
    name: 'recruitment',
    label: 'Senior & Specialist Recruitment',
  },
  {
    name: 'performance',
    label: 'Performance & Motivation',
  },
  {
    name: 'staffing',
    label: 'Staffing & Talent Management',
  },
];

const CaseStudiesPage = ({
  data: { allContentfulCaseStudy, caseStudiesPage },
}) => {
  const [queryParams, setQueryParams] = useQueryParams({
    service: JSONStringifyParam,
  });
  const { service: serviceQueryParam } = queryParams;

  const [showFilterToggles, setShowFilterToggles] = useState(
    typeof serviceQueryParam !== 'undefined' && serviceQueryParam.length > 0,
  );

  const [, setForceRenderStateValue] = useState(1);
  const forceRender = () => setForceRenderStateValue(Math.random() * 1000);

  const activeFilter =
    !!serviceQueryParam && Array.isArray(serviceQueryParam)
      ? serviceQueryParam
      : [];
  const setActiveFilter = (filter) => {
    ReactGA.event({
      category: GACategory,
      action: 'filter',
      value: typeof filter === 'undefined' ? '' : filter.join(','),
    });
    setQueryParams({
      service: filter,
    });
    forceRender();
  };

  const caseStudies = allContentfulCaseStudy.nodes;
  const filteredCaseStudies = caseStudies.filter(
    (caseStudy) =>
      activeFilter.length === 0 ||
      activeFilter.includes(caseStudy.service.serviceTypeId),
  );

  const addToFilter = (filterType, allFilters) => {
    if (activeFilter.length === 0) {
      const allFiltersButFilterType = allFilters.filter(
        (type) => type !== filterType,
      );
      return setActiveFilter(allFiltersButFilterType);
    }
    if (activeFilter.includes(filterType)) {
      return setActiveFilter(
        activeFilter.filter((type) => type !== filterType),
      );
    }
    return setActiveFilter([...activeFilter, filterType]);
  };

  const toggles = filterToggles.map(({ ...toggle }) => ({
    ...toggle,
    toggles: activeFilter,
    onChange: useCallback(() =>
      addToFilter(
        toggle.name,
        filterToggles.map((t) => t.name),
      ),
    ),
  }));
  const onShowFilterToggles = useCallback(() => setShowFilterToggles(true));
  const onHideFilterToggles = useCallback(() => setShowFilterToggles(false));
  const onResetFilterToggles = useCallback(() => setActiveFilter(undefined));

  return (
    <PageLayout
      leadInHeader={
        <CaseStudyLeadInHeader
          heading={caseStudiesPage.heading || caseStudiesPage.title}
          crumbs={[
            {
              url: '/case-studies/',
              title: caseStudiesPage.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={caseStudiesPage.title}
        keywords={caseStudiesPage.fields.keywords}
        description={caseStudiesPage.metaDescription}
        canonicalPath="/case-studies/"
        thumbnail={caseStudiesPage.jsonLdThumbnailImage}
      />

      <Container as="section" mt={2} mb={4} maxW="container.lg">
        <FilterToggles>
          <FilterToggles.ShowHideRow
            show={showFilterToggles}
            showText="Filter case studies"
            hideText="Hide case study filters"
            onShow={onShowFilterToggles}
            onHide={onHideFilterToggles}
          />
          <FilterToggles.TogglesCollapse show={showFilterToggles}>
            <FilterToggles.TogglesBox
              description="Show case studies relating to:"
              onReset={onResetFilterToggles}
              toggles={toggles}
            />
            <Text mt={2}>
              Showing {filteredCaseStudies.length} of {caseStudies.length} case
              studies
            </Text>
          </FilterToggles.TogglesCollapse>
        </FilterToggles>
      </Container>

      <Container as="section" mb={4} maxW="container.lg">
        <Html
          source={caseStudiesPage.fields.contentHtml}
          headerLevelStart={1}
        />
      </Container>

      <Container maxW="container.lg" mt={2} mb={4}>
        <SimpleGrid columns={[1, 1, 2]} spacing={8}>
          {filteredCaseStudies.map((caseStudy, index) => (
            <Flex as="section" key={caseStudy.id}>
              <CaseStudyCard
                caseStudy={caseStudy}
                imageOnRight={index % 2 === 0}
              />
            </Flex>
          ))}
        </SimpleGrid>
        {filteredCaseStudies.length < 1 && (
          <Box>
            <Heading as="h2">No case studies found</Heading>
            <Text>
              See our
              <CanonicalLink to="/services/">services</CanonicalLink>.
            </Text>
          </Box>
        )}

        <Box as="footer" textAlign="center" my={12}>
          <CallMeBack
            size="lg"
            callBackText="Specific question? Get in touch"
          />
        </Box>
      </Container>

      <Jumbotron as="aside" py={8} bg="tt.darkBlue">
        <NewsletterSignup />
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query CaseStudiesPageQuery {
    caseStudiesPage: contentfulPage(slug: { eq: "case-studies" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    allContentfulCaseStudy(
      sort: { fields: [ordering, createdAt], order: DESC }
      filter: { ordering: { ne: -1 }, title: { ne: null } }
    ) {
      nodes {
        id
        title
        slug
        title
        metaDescription
        createdAt
        updatedAt
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        jsonLdThumbnailImage: featuredImage {
          ...JsonLdThumbnailImage
        }
        quotes {
          id
          person
          position
          company
          # get raw text here since we need it for structured data
          text {
            internal {
              content
            }
          }
          fields {
            textHtml
          }
        }
        service {
          id
          title
          slug
          serviceTypeId
        }
        fields {
          path
          url
          writtenAt
          isNew
          keywords
        }
      }
    }
  }
`;

export default CaseStudiesPage;
