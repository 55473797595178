import React from 'react';
import { Divider, Box, HStack } from '@chakra-ui/react';

import ContentCard from 'timelesstime-ui/components/content-card';
import NewBadge from 'timelesstime-ui/components/new-badge';
import BlockQuote from 'timelesstime-ui/components/block-quote';
import ButtonLink from 'timelesstime-ui/components/button-link';
import CaseStudyStructuredData from '../structured-data/case-study';
import TypeBadge from '../type-badge';

const CaseStudyCard = ({ caseStudy, ...props }) => (
  <ContentCard
    url={caseStudy.fields.path}
    title={
      <Box>
        <Box>
          <ContentCard.Heading to={caseStudy.fields.path} mb={2}>
            {caseStudy.title}
          </ContentCard.Heading>
          <HStack spacing={1}>
            <TypeBadge
              type={caseStudy.service.title}
              title={caseStudy.service.title.replace(' ...', '')}
            />
            <NewBadge isNew={caseStudy.fields.isNew} />
          </HStack>
        </Box>
        <Divider mt={2} mb={4} />
      </Box>
    }
    image={null}
    imageMargin={6}
    structuredData={
      <CaseStudyStructuredData
        slug={caseStudy.slug}
        title={caseStudy.title}
        description={caseStudy.metaDescription}
        keywords={caseStudy.fields.keywords}
        plaintextContent={caseStudy.fields.plaintextContent}
        serviceSlug={caseStudy.service.slug}
        keyQuoteText={caseStudy?.quotes?.[0]?.text?.internal?.content || ''}
        createdAt={new Date(caseStudy.createdAt)}
        updatedAt={new Date(caseStudy.updatedAt)}
        thumbnailImage={caseStudy.jsonLdThumbnailImage}
        featuredImage={caseStudy.jsonLdFeaturedImage}
        shortMeta
      />
    }
    excerpt=""
    moreText={
      <ButtonLink
        size="sm"
        variant="solid"
        colorScheme="orange"
        width="auto"
        to={caseStudy.fields.path}
        mt={8}
        mb={4}
      >
        Read the case study in full
      </ButtonLink>
    }
    flexGrow="1"
    display="flex"
    flexDir="column"
    wrapperProps={{
      flexGrow: '1',
      display: 'flex',
      flexDir: 'column',
    }}
    {...props}
  >
    <BlockQuote
      mb={4}
      flexGrow="1"
      text={caseStudy?.quotes?.[0]?.text || ''}
      person={caseStudy?.quotes?.[0]?.person || ''}
      position={caseStudy?.quotes?.[0]?.position || ''}
      company={caseStudy?.quotes?.[0]?.company || ''}
    />
  </ContentCard>
);

export default CaseStudyCard;
